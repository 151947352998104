@font-face {
  font-family: "Visby-Round";
  src: url("./fonts/Visby-Round.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Visby-Round";
  src: url("./fonts/Visby-Round-Bold.otf");
  font-weight: bold;
  font-style: bold;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  font-size: 38px;
  font-weight: bold;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

p {
  font-size: 16px;
}

table {
  border-spacing: 0px !important;
}

/* antd */
.ant-table-thead > tr > th {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 500;
  padding: 8px 16px !important;
}

.ant-table-tbody > tr > td {
  text-align: center !important;
  padding: 0px 0px !important;
}

.ant-input {
  height: 40px;
  align-items: center;
  margin-bottom: 0px;
}

.ant-select-selector {
  align-items: center;
  border-radius: 10px !important;
}

.ant-select-single .ant-select-selector {
  height: 40px !important;
  align-items: center;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  padding: 5px !important;
}

textarea.ant-input {
  height: auto !important;
}

.ant-input-number-input {
  height: 40px !important;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  font-size: 16px;
}

.ant-btn {
  height: 40px !important;
  border: 0px !important;
  font-size: 16px !important;
}

.ant-modal-body {
  padding: 10px 0px !important;
}

.ant-modal {
  width: 400px !important;
}

.ant-modal.countryModal {
  width: 900px !important;
}

.ant-checkbox-group-item {
  display: inline-block !important;
  margin-right: 10px !important;
  width: 250px !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 14px !important;
}

.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  display: none;
}

.ant-picker-now {
  display: none !important;
}

.ant-typography {
  font-family: "Visby-Round" !important;
  color: #383838 !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
